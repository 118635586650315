import React, { useRef } from "react";
import pharmacyshop from "../images/modalimg/pharmacyshop.png";
import { Link } from "react-router-dom";
import { BiSolidVideos } from "react-icons/bi";
import { MdContactEmergency } from "react-icons/md";
import { CgLivePhoto } from "react-icons/cg";
const PharmacyShop = () => {
  const videoRef = useRef(null); // Reference for the video section
  const fromRef = useRef(null);

  const handleScrollToVideo = () => {
    setTimeout(() => {
      videoRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const handleScrollToFrom = () => {
    setTimeout(() => {
      fromRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
  return (
    <div className="relative mt-20">
      {/* Image with overlay */}
      <div className="relative h-[70vh]">
        <img src={pharmacyshop} alt="" className="h-full w-full object-cover" />

        {/* Overlay with buttons */}
        <div className="absolute inset-0 bg-black bg-opacity-70 flex flex-col items-center justify-center gap-8">
          <h1 className="text-white text-3xl font-bold">
            MerinaSoft Pharmacy Management software
          </h1>
          <div className="flex flex-wrap items-center justify-center gap-4">
            <button
              onClick={handleScrollToVideo}
              className="px-4 py-2 text-white rounded-md flex items-center gap-2 font-medium bg-green-600 shadow-2xl hover:bg-green-700"
            >
              Watch Demo
              <span className="text-red-900">
                <BiSolidVideos />
              </span>
            </button>
            <a
              href="http://merinasoft.com"
              target="_blank"
              rel="noopener noreferrer" // Security best practice when using target="_blank"
            >
              <button className="px-4 py-2 bg-white text-black rounded-md flex items-center gap-2 font-medium">
                Live Demo
                <span className="text-red-900 ">
                  <CgLivePhoto />
                </span>
              </button>
            </a>

            <button
              onClick={handleScrollToFrom}
              className="px-4 py-2 bg-white text-black rounded-md flex items-center gap-2 font-medium"
            >
              Request Demo
              <span className="text-red-900 ">
                <MdContactEmergency />
              </span>
            </button>
          </div>
        </div>
      </div>

      {/* About text */}
      <div className="max-w-[1200px] mx-auto my-4 px-4">
        <h2 className="text-2xl font-bold border-b-4 border-black md:inline-block pb-2">
          About
        </h2>
        <p className="mt-2 text-justify">
          Merinasoft Pharma POS is a modern pharmacy management software
          designed to optimize operations in retail pharmacies. At its core is a
          comprehensive Medicine Database repository, housing an extensive range
          of pharmaceuticals, allowing users to quickly access information about
          products, dosages, and pricing. This feature enhances customer service
          by providing accurate and timely information. The software includes a
          seamless Bulk Sale feature, enabling pharmacies to efficiently manage
          large orders and offer competitive discounts, thus attracting more
          customers. With advanced inventory management, the system ensures that
          stock levels are monitored in real-time, reducing the risk of
          shortages or overstocking. Customizable reporting tools provide
          insights into sales trends and inventory turnover, helping pharmacy
          owners make informed decisions. Merinasoft's Pharma POS empowers
          pharmacies to enhance their efficiency, improve customer satisfaction,
          and drive growth in an increasingly competitive healthcare landscape.
        </p>
      </div>

      {/* Video Section */}
      <div ref={videoRef} className="max-w-[1200px] mx-auto my-4 px-4">
        <h2 className="text-2xl font-bold my-2 pb-2">Watch Demo</h2>
        <div className="my-2 h-[70vh]">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/ZUVTNTgf2lE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="h-full rounded-md"
          ></iframe>
        </div>
      </div>

      {/* Request Demo Form */}
      <div ref={fromRef} className="max-w-[1200px] mx-auto my-4 px-4">
        <h2 className="text-2xl font-bold my-2 pb-2">Request Demo</h2>
        <div className="flex justify-center mt-4 w-full">
          <div className="bg-white shadow-lg rounded-lg p-8 w-full">
            <h2 className="text-2xl font-bold text-black mb-2 text-center">
              Form Submit
            </h2>
            <form className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-black font-medium">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  placeholder="Enter Your Name"
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none shadow-sm"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-black font-medium">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter Your Email"
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none shadow-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="number"
                  className="block text-black font-medium"
                >
                  Phone Number
                </label>
                <input
                  type="number"
                  id="number"
                  placeholder="Enter Your Number"
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none shadow-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="address"
                  className="block text-black font-medium"
                >
                  Address
                </label>
                <input
                  type="text"
                  id="address"
                  placeholder="Enter Your Address"
                  className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none shadow-sm"
                />
              </div>

              <button
                type="submit"
                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md shadow-lg transition-all duration-300 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PharmacyShop;
