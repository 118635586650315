import React, { useEffect, useState } from "react";
import MerinasoftLogo from "../images/merinasoft.png";
import Computer from "../images/icon/computer.png";
import Hardwarea_Sanatary from "../images/icon/hardwarea_sanatary.png";
import Kaporer_Business from "../images/icon/kaporer_business.png";
import Khabar_Icon from "../images/icon/khabar_icon.png";
import Pharmacy from "../images/icon/pharmacy.png";
import Rod_Cement from "../images/icon/rod_cement.png";
import Shoes_Business from "../images/icon/shoes_business.png";
import Super_Shop from "../images/icon/super_shop.png";
import { IoArrowRedoSharp } from "react-icons/io5";
import { IoArrowUndo } from "react-icons/io5";
import { TbArrowBigDownLineFilled } from "react-icons/tb";

import { FaHandPointUp } from "react-icons/fa";

const BusniessSoftware = () => {
  const [buttonAnimationVisible, setButtonAnimationVisible] = useState(true);

  //
  return (
    <div className="h-[48rem] w-full flex items-center justify-center">
      <div className="bg-gray-900 w-[11rem] h-[11rem] rounded-full flex items-center justify-center">
        <div className="bg-blue-900 w-[10.5rem] h-[10.5rem] rounded-full gap-4 flex flex-col items-center justify-center">
          <span className="bg-white w-[4rem] h-[4rem] rounded-full flex items-center justify-center">
            <img
              src={MerinasoftLogo}
              className="w-[4rem] h-[4rem] rounded-full"
              alt="Merinasoft Logo"
            />
          </span>

          <h2 className="font-bold text-xl text-white text-center">
            {" "}
            বিজনেস <span className="block">সফ্টওয়্যার</span>
          </h2>
        </div>
      </div>

      {/* Box seection */}
      <section>
        <a
          href="http://merinasoft.com/computershop"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="relative cursor-pointer">
            <div className="bg-black hover:scale-105 hover:shadow-xl hover:shadow-gray-800 active:scale-95 w-[8rem] h-[8rem] rounded-full flex items-center justify-center absolute top-[-14rem] left-[-2.5rem] z-10">
              <div className="bg-blue-900 w-[7.5rem] h-[7.5rem] rounded-full gap-2 flex flex-col items-center justify-center">
                <span className="bg-white w-[3rem] h-[3rem] rounded-full flex items-center justify-center">
                  <img
                    src={Computer}
                    className="w-[3rem] h-[3rem] rounded-full"
                    alt="Computer Logo"
                  />
                </span>

                <h2 className="font-semibold text-sm text-white text-center">
                  কম্পিউটার
                  <span className="block">শপ</span>
                </h2>
              </div>
            </div>
          </div>
        </a>
        {/* Super Shop */}
        <a
          href="http://merinasoft.com/supershop"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="relative cursor-pointer">
            <div className="bg-black hover:scale-105 hover:shadow-xl hover:shadow-gray-800 active:scale-95 w-[8rem] h-[8rem] rounded-full flex items-center justify-center absolute top-[-18.5rem] left-[-9.5rem]">
              <div className="bg-blue-900 w-[7.5rem] h-[7.5rem] rounded-full gap-2 flex flex-col items-center justify-center">
                <span className="bg-white w-[3rem] h-[3rem] rounded-full flex items-center justify-center">
                  <img
                    src={Super_Shop}
                    className="w-[3rem] h-[3rem] rounded-full"
                    alt="Super_Shop"
                  />
                </span>

                <h2 className="font-bold text-sm text-white text-center">
                  {" "}
                  সুপার
                  <span className="block">শপ</span>
                </h2>
              </div>
            </div>
            {buttonAnimationVisible && (
              <div className="absolute z-50 top-[-14rem] left-[-5rem]">
                <FaHandPointUp className="text-[3rem] text-slate-200 continuous-slide-in" />
              </div>
            )}
          </div>
        </a>
        {/* Pharmacy */}
        <a
          href="http://merinasoft.com/pharmacyshop"
          target="_blank"
          rel="noopener noreferrer"
        >
        <div className="relative cursor-pointer ">
          <div className="bg-black hover:scale-105 hover:shadow-xl hover:shadow-gray-800 active:scale-95 w-[8rem] h-[8rem] rounded-full flex items-center justify-center absolute top-[-14rem] left-[-16.5rem]">
            <div className="bg-blue-900 w-[7.5rem] h-[7.5rem] rounded-full gap-2 flex flex-col items-center justify-center">
              <span className="bg-white w-[3rem] h-[3rem] rounded-full flex items-center justify-center">
                <img
                  src={Pharmacy}
                  className="w-[3rem] h-[3rem] rounded-full"
                  alt="Pharmacy"
                />
              </span>

              <h2 className="font-semibold text-sm text-white text-center">
                {" "}
                ফার্মেসী
                <span className="block">ব্যবসা</span>
              </h2>
            </div>
          </div>
        </div>
        </a>
        {/*=============== Lower section ====================*/}
        <a
          href="http://merinasoft.com/sematary-hardware"
          target="_blank"
          rel="noopener noreferrer"
        >
        <div className="relative cursor-pointer">
          <div className="bg-black hover:scale-105 hover:shadow-xl hover:shadow-gray-800 active:scale-95 w-[8rem] h-[8rem] rounded-full flex items-center justify-center absolute top-[6rem] left-[-16.5rem]">
            <div className="bg-blue-900 w-[7.5rem] h-[7.5rem] rounded-full gap-2 flex flex-col items-center justify-center">
              <span className="bg-white w-[3rem] h-[3rem] rounded-full flex items-center justify-center">
                <img
                  src={Hardwarea_Sanatary}
                  className="w-[3rem] h-[3rem] rounded-full"
                  alt="Hardwarea_Sanatary"
                />
              </span>

              <h2 className="font-semibold text-sm text-white text-center">
                {" "}
                হার্ডওয়্যার,
                <span className="block">স্যানেটারী</span>
              </h2>
            </div>
          </div>
        </div>
        </a>
        {/* cloth */}
        <a
          href="http://merinasoft.com/clothshop"
          target="_blank"
          rel="noopener noreferrer"
        >
        <div className="relative cursor-pointer">
          <div className="bg-black hover:scale-105 hover:shadow-xl hover:shadow-gray-800 active:scale-95 w-[8rem] h-[8rem] rounded-full flex items-center justify-center absolute top-[11rem] left-[-9.5rem]">
            <div className="bg-blue-900 w-[7.5rem] h-[7.5rem] rounded-full gap-2 flex flex-col items-center justify-center">
              <span className="bg-white w-[3rem] h-[3rem] rounded-full flex items-center justify-center">
                <img
                  src={Kaporer_Business}
                  className="w-[3rem] h-[3rem] rounded-full"
                  alt="Kaporer_Business"
                />
              </span>

              <h2 className="font-bold text-sm text-white text-center">
                {" "}
                কাপড়ের
                <span className="block">ব্যবসা</span>
              </h2>
            </div>
          </div>
        </div>
        </a>
        {/* restarent */}
        <a
          href="http://merinasoft.com/restaurantshop"
          target="_blank"
          rel="noopener noreferrer"
        >
        <div className="relative cursor-pointer">
          <div className="bg-black hover:scale-105 hover:shadow-xl hover:shadow-gray-800 active:scale-95 w-[8rem] h-[8rem] rounded-full flex items-center justify-center absolute top-[6rem] left-[-2.5rem]">
            <div className="bg-blue-900 w-[7.5rem] h-[7.5rem] rounded-full gap-2 flex flex-col items-center justify-center">
              <span className="bg-white w-[3rem] h-[3rem] rounded-full flex items-center justify-center">
                <img
                  src={Khabar_Icon}
                  className="w-[3rem] h-[3rem] rounded-full"
                  alt="Computer Logo"
                />
              </span>

              <h2 className="font-semibold text-sm text-white text-center">
                {" "}
                খাবারের
                <span className="block">ব্যবসা</span>
              </h2>
            </div>
          </div>
        </div>
        </a>
        {/* Left Right site  */}
        {/* sheos */}
        <a
          href="http://merinasoft.com/footwearshop"
          target="_blank"
          rel="noopener noreferrer"
        >
        <div className="relative cursor-pointer">
          <div className="bg-black hover:scale-105 hover:shadow-xl hover:shadow-gray-800 active:scale-95 w-[5rem] h-[9rem] rounded-full flex items-center justify-center absolute top-[-4.5rem] left-[0.5rem]">
            <div className="bg-blue-900 w-[4.8rem] h-[8.5rem] rounded-full gap-2 flex flex-col items-center justify-center">
              <span className="bg-white w-[3rem] h-[3rem] rounded-full flex items-center justify-center">
                <img
                  src={Shoes_Business}
                  className="w-[3rem] h-[3rem] rounded-full"
                  alt="Computer Logo"
                />
              </span>

              <h2 className="font-semibold text-sm text-white text-center">
                {" "}
                জুতার<span className="block">ব্যবসা</span>
              </h2>
            </div>
          </div>
        </div>
        </a>
        {/* cement */}
        <a
          href="http://merinasoft.com/supershop"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="relative cursor-pointer">
            <div className="bg-black hover:scale-105 hover:shadow-xl hover:shadow-gray-800 active:scale-95 w-[5rem] h-[9rem] rounded-full flex items-center justify-center absolute top-[-4.5rem] left-[-16.6rem]">
              <div className="bg-blue-900 w-[4.8rem] h-[8.5rem] rounded-full gap-2 flex flex-col items-center justify-center">
                <span className="bg-white w-[3rem] h-[3rem] rounded-full flex items-center justify-center">
                  <img
                    src={Rod_Cement}
                    className="w-[3rem] h-[3rem] rounded-full"
                    alt="Computer Logo"
                  />
                </span>

                <h2 className="font-semibold text-sm text-white text-center">
                  {" "}
                  রড, সিমেন্ট<span className="block">ব্যবসা</span>
                </h2>
              </div>
            </div>
          </div>
        </a>
      </section>

      {/* ============================Section Arrows=============================== */}
      <div className="relative">
        <span className="absolute top-[5rem] left-[-3.5rem]">
          <IoArrowUndo className="w-10 h-12 rotate-[200deg] text-green-800" />
        </span>
      </div>
      <div className="relative">
        <span className="absolute top-[5rem] left-[-10rem]">
          <IoArrowRedoSharp className="w-10 h-12 rotate-[160deg] text-green-800" />
        </span>
      </div>
      <div className="relative">
        <span className="absolute top-[6rem] left-[-7.5rem]">
          <TbArrowBigDownLineFilled className="w-16 h-20 rotate-[0deg] text-green-800" />
        </span>
      </div>
      {/* ===============top Arrows=============== */}
      <div className="relative">
        <span className="absolute top-[-7.5rem] left-[-3.5rem]">
          <IoArrowRedoSharp className="w-10 h-12 rotate-[-60deg] text-green-800" />
        </span>
      </div>
      <div className="relative">
        <span className="absolute top-[-7.5rem] left-[-10rem]">
          <IoArrowUndo className="w-10 h-12 rotate-[50deg] text-green-800" />
        </span>
      </div>
      <div className="relative">
        <span className="absolute top-[-10.5rem] left-[-7.5rem]">
          <TbArrowBigDownLineFilled className="w-16 h-20 rotate-[180deg] text-green-800" />
        </span>
      </div>
    </div>
  );
};

export default BusniessSoftware;
